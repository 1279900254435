code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$theme-colors: (
  "primary": #2c4a9a,
  "danger": #e52321,
  "success": #5eb98f,
  "warning": #f6a13d
);

@import url('https://fonts.googleapis.com/css?family=Work+Sans:regular,bold,italic&subset=latin,latin-ext');


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --ion-color-primary: #2c4a9a;
  --color-primary: #2c4a9a;
  --color-primary-highlight: #2c4a9a;
  --amplify-font-family: 'Work Sans', sans-serif;
  --amplify-primary-color: #2c4a9a;
  --amplify-primary-contrast:	#ffffff;
  --amplify-tertiary-tint:	#7da1ff;
  --amplify-primary-shade:	 #2c4a9a;
  --amplify-secondary-color	:#152939;
  --amplify-secondary-contrast: #ffffff;
  --amplify-secondary-tint	:#31465f;
  --amplify-secondary-shade	:#1F2A37;
  --amplify-tertiary-color	:#5d8aff;
  --amplify-tertiary-contrast:	#ffffff;
  --amplify-tertiary-shade:#537BE5;
  --button-background-color: #2c4a9a;
  --button-click: #2c4a9a;
  --link-color: #2c4a9a;
}
